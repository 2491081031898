export default function (el: HTMLElement, binding: any) {
  if (binding.value) {
    el.classList.add('dtt-loading-active');
    el.style.setProperty('--x-shift', el.scrollLeft + 'px');
    el.style.setProperty('--y-shift', el.scrollTop + 'px');
  } else {
    el.classList.remove('dtt-loading-active');
    el.style.setProperty('--x-shift', '0');
    el.style.setProperty('--y-shift', '0');
  }
}
