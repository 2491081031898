
import { Component, Emit, ModelSync, Prop, Vue } from 'vue-property-decorator';
import DttButton from '@/components/Button/index.vue';
import DttIcon from '@/components/Icon/index.vue';
import { ButtonsGroupItem } from '@/components/ButtonsGroup/types';

@Component({
  name: 'DttButtonsGroup',
  components: { DttButton, DttIcon },
})
export default class ButtonsGroup extends Vue {
  @ModelSync('value', 'change', { default: null })
  modelValue?: any;
  @Prop({ type: Array, default: () => [] }) options!: ButtonsGroupItem[];
  @Prop({ type: Boolean, default: false }) flat?: boolean;
  @Prop({ type: Boolean, default: false }) iconOnly?: boolean;

  isActiveOption(option: ButtonsGroupItem) {
    return option.value === this.modelValue;
  }

  @Emit()
  click(option: ButtonsGroupItem) {
    this.modelValue = option.value;
    return option;
  }
}
