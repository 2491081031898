
import { Component, Prop, Vue } from 'vue-property-decorator';
import DttTypo from '@/components/Typo/index.vue';
import { TabsSize, TabsItems, TabsMode } from '@/components/Tabs/types';
import { PropType } from 'vue';
import DttBadge from '@/components/Badge/index.vue';

@Component({
  name: 'DttTabs',
  components: { DttBadge, DttTypo },
})
export default class Tabs extends Vue {
  @Prop({ type: String, default: 'md' }) size!: TabsSize;
  @Prop({ type: Array as PropType<TabsItems>, default: () => [] })
  items!: TabsItems;
  @Prop({ type: String }) mode?: TabsMode;
  @Prop({ type: String }) initialActiveTab?: string;
  @Prop({ type: String }) width?: string;

  activeTab = '';

  get activeTabIndex() {
    if (this.mode === 'routeBounded') {
      return this.items.findIndex(
        (item) =>
          this.$route?.path === item.to ||
          (item.basePath && this.$route?.path.includes(item.basePath))
      );
    }

    return this.items.findIndex((item) => item.to === this.activeTab);
  }

  get itemWidth() {
    return `calc(100% / ${this.items.length || 1})`;
  }

  get activeStyles() {
    return {
      width: this.itemWidth,
      left: `calc(${this.itemWidth} * ${this.activeTabIndex}`,
    };
  }

  onTabClick(to: string) {
    if (this.activeTab === to) return;
    this.activeTab = to;
    this.$emit('change', to);

    if (this.mode === 'routeBounded') this.$router.replace(to);
  }

  beforeMount() {
    const defaultIndex = this.activeTabIndex === -1 ? 0 : this.activeTabIndex;
    this.activeTab = this.initialActiveTab || this.items[defaultIndex].to;
  }
}
