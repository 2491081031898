export type ViewportYArea = 'top' | 'bottom';
export type ViewportXArea = 'left' | 'right';
export type ViewportArea = `${ViewportXArea}-${ViewportYArea}`;

export function getElementViewportArea(el: Element) {
  const { x, y } = el.getBoundingClientRect();
  const xArea: ViewportXArea = x > window.innerWidth / 2 ? 'right' : 'left';
  const yArea: ViewportYArea = y > window.innerHeight / 2 ? 'bottom' : 'top';
  return {
    area: `${xArea}-${yArea}` as ViewportArea,
    x: xArea,
    y: yArea,
  };
}
